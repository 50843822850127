@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Poetsen+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}

.bg-color1{
  background-color: #367ba0;
}

.animated{
  transform: translateX(10px);
}
.logo_hover:hover .animated{
  transform:  translateX(0);
  transition: all 1s ease-in-out;
}

.background{
  background-image: url('./images/desk.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}



